import Events, { EventTypes } from '../lib/events';
import Logger from '../lib/logger';
import PluginBase from './base';
import * as prebidFunctions from '../lib/prebid_helper';
import PrebidEvents from '../lib/prebid_events';
import ScriptLoader from '../lib/script_loader';

export default class RubiconDemandManagerBidding extends PluginBase {
  onSettingsLoaded() {
    if (this.biddingWithRDM()) {
      Logger.log('Installing plugin: Rubicon Demand Manager Bidding');

      Events.on(EventTypes.bidManagerCreated, () => {
        this.definePbjs();
        this.loadRDMScript();
        this.app.bidManager.addBiddingPartner(this);
      });
    }
  }

  /**
   * Determine whether we are using the Rubicon Demand Manager for bidding
   * @return {boolean}
   */
  biddingWithRDM() {
    return (
      this.app.settings.prebid.biddingEnabled &&
      this.app.settings.prebid.rubiconDemandManagerEnabled &&
      (this.app.settings.prebid.rubiconDemandManagerUrl || this.app.settings.prebid.loadRDMScriptInConcertAds === false)
    );
  }

  /**
   * Define global pbjs namespace
   */
  definePbjs() {
    window.pbjs = window.pbjs || { que: [] };
  }

  /**
   * Loading the Rubicon Demand Manager script
   * We are using the Bid Manager beforeFirstBid method to make sure the script loads before we start bidding
   * @return {undefined}
   */
  loadRDMScript() {
    const dependency = {
      url: this.app.settings.prebid.rubiconDemandManagerUrl,
      timeout: this.app.settings.prebid.rdmScriptTimeout || 3000,
    };

    this.app.bidManager.beforeFirstBid(() => {
      return new Promise((resolve, reject) => {
        // In cases where the native page loads this script, we don't want to try to load it twice.
        // This fork allows us to only load when actually necessary.
        if (
          this.app.settings.prebid.loadRDMScriptInConcertAds === false ||
          document.querySelector(`script[src="${dependency.url}"]`)
        ) {
          Logger.log(`Rubicon Demand Manager script at ${dependency.url} already exists in the DOM`);
          this.setupAndListenForPrebidEvents();
          resolve();
        } else {
          new ScriptLoader()
            .load(dependency)
            .then(() => {
              Logger.log(`Rubicon Demand Manager script ${dependency.url} has loaded successfully.`);
              this.setupAndListenForPrebidEvents();
              resolve();
            })
            .catch(error => {
              Logger.error(`Error loading Rubicon Demand Manager script: ${error}`);
              reject(error);
            });
        }
      });
    });
  }

  /**
   * Setting up Prebid Events so we can listen to them
   * We need to listen to Prebid Events even if we aren't setting up Prebid with Concert Ads
   */
  setupAndListenForPrebidEvents() {
    Logger.log('Prebid events are being set up.');
    this.app.prebidEvents = new PrebidEvents({ app: this.app });
    prebidFunctions.listenForEvents(this.app);
  }

  /**
   * Build array of slot objects ready for Prebid with Rubicon Demand Manager
   */
  buildBiddingQueues(queue) {
    const biddingQueue = [];

    queue.forEach(bid => {
      biddingQueue.push(bid.slot.slot);
    });

    return biddingQueue;
  }

  /**
   * Fetch bids from Prebid with Rubicon Demand Manager
   * @type {Promise}
   */
  fetchBidsFor({ queueOfBids, timeout }) {
    let bidsFetched = false;

    return new Promise(resolve => {
      if (!prebidFunctions.prebidScriptLoaded()) {
        Logger.log(`The prebid script hasn't been loaded so we aren't fetching bids with RDM.`);
        return resolve();
      }

      const gptSlotObjects = this.buildBiddingQueues(queueOfBids);
      const gptSlotIds = gptSlotObjects.map(slot => slot?.getSlotElementId());
      Logger.log(`Fetching bids for the following slots with Rubicon: [${gptSlotIds}]`);

      const requestBids = () => {
        pbjs.rp.requestBids({
          callback: function() {
            Logger.log(`Rubicon Demand Manager bids have been fetched`);
            bidsFetched = true;
            resolve();
          },
          gptSlotObjects,
          data: {
            keywords: {
              keywords: this.app.variables.keywords,
              permutive: this.app.variables.permutive,
            },
            inventory: {
              position: this.getPositions(queueOfBids),
              ...this.app.variables,
            },
          },
        });
      };

      if (!pbjs.rp) {
        pbjs.que.push(function() {
          requestBids();
        });
      } else {
        requestBids();
      }

      // RDM wants us to set a failsafe timeout in case things go awry
      setTimeout(() => {
        if (!bidsFetched) {
          Logger.log(
            `Rubicon Demand Manager took longer than ${timeout} ms to fetch bids so we are calling the failsafe timeout`
          );
          resolve();
        }
      }, timeout);
    }).catch(e => {
      Logger.log(`There was an error fetching bids with RDM: ${e}`);
    });
  }

  /**
   * Add RDM targeting to bids
   */
  addTargeting() {
    if (typeof pbjs === 'undefined' || typeof pbjs.setTargetingForGPTAsync !== 'function') return;
    Logger.log('Adding Rubicon Demand Manager targeting to bids.');
    pbjs.setTargetingForGPTAsync();
  }

  /**
   * Get the position names of all of the slots being bidded on
   * @param {Array}
   * @return {Array}
   */
  getPositions(bids) {
    return bids.map(bid => bid.slot.data.name);
  }
}
