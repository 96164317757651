import Events, { EventTypes } from '../lib/events';
import Logger from '../lib/logger';
import PluginBase from './base';
import ScriptLoader from '../lib/script_loader';
import { getCanonicalUrl } from '../lib/utils';

export default class AmazonBidding extends PluginBase {
  onSettingsLoaded() {
    if (this.biddingWithAmazon()) {
      Logger.log('Installing plugin: Amazon Bidding');

      Events.on(EventTypes.bidManagerCreated, () => {
        this.loadAmazonScript();
        this.app.bidManager.addBiddingPartner(this);
      });
    }
  }

  /**
   * Determine whether we are bidding with Amazon
   * @return {boolean}
   */
  biddingWithAmazon() {
    return (
      this.app.settings.prebid.biddingEnabled &&
      this.app.settings.prebid.amazonPubId &&
      (this.app.settings.prebid.amazonUrl || this.app.settings.prebid.loadAmazonScriptInConcertAds === false)
    );
  }

  /**
   * Loading the Amazon script
   * We are using the Bid Manager beforeFirstBid method to make sure the script loads before we start bidding
   * @return {undefined}
   */
  loadAmazonScript() {
    const dependency = {
      url: this.app.settings.prebid.amazonUrl,
      timeout: this.app.settings.prebid.amazonScriptTimeout || 3000,
    };

    this.app.bidManager.beforeFirstBid(() => {
      return new Promise((resolve, reject) => {
        if (
          this.app.settings.prebid.loadAmazonScriptInConcertAds === false ||
          document.querySelector(`script[src="${dependency.url}"]`)
        ) {
          Logger.log(`Amazon script already exists in the DOM.`);
          setUpAmazon(this.app);
          resolve();
        } else {
          new ScriptLoader()
            .load(dependency)
            .then(url => {
              Logger.log(`Amazon (TAM) script ${url} has loaded successfully.`);
              setUpAmazon(this.app);
              resolve();
            })
            .catch(error => {
              Logger.error(`Error loading Amazon script: ${error}`);
              // We still want to set up Amazon even if the script takes a long time to load
              setUpAmazon(this.app);
              reject(error);
            });
        }
      });
    });
  }

  /**
   * Build up the Amazon bid queues
   * @param  {Array} array of bids
   * @return {undefined}
   */
  buildBiddingQueues(queue) {
    const biddingQueue = [];

    queue.forEach(bid => {
      biddingQueue.push({
        slotID: bid.slot.id,
        slotName: bid.app.settings.slug,
        sizes: bid.slot.sizes,
      });
    });

    return biddingQueue;
  }

  /**
   * Fetch bids from Amazon.
   * @type {Promise}
   */
  fetchBidsFor({ queueOfBids, timeout }) {
    let bidsFetched = false;
    if (typeof apstag === 'undefined' || typeof apstag.setDisplayBids !== 'function') return;

    return new Promise(resolve => {
      const slots = this.buildBiddingQueues(queueOfBids);
      const slotIds = slots.map(slot => slot?.slotID);
      Logger.log(`Fetching bids for the following slots with Amazon: [${slotIds}]`);

      apstag.fetchBids(
        {
          slots,
        },
        function() {
          Logger.log(`Amazon bids have been fetched`);
          bidsFetched = true;
          resolve();
        }
      );

      // Set failsafe timeout in case things go awry
      setTimeout(() => {
        if (!bidsFetched) {
          Logger.log(`Amazon took longer than ${timeout} ms to fetch bids so we are calling the failsafe timeout`);
          resolve();
        }
      }, timeout);
    }).catch(e => {
      Logger.log(`There was an error fetching bids with Amazon: ${e}`);
    });
  }

  /**
   * Add Amazon targeting to bids
   */
  addTargeting() {
    if (typeof apstag === 'undefined' || typeof apstag.setDisplayBids !== 'function') return;
    Logger.log('Adding Amazon targeting to bids.');
    apstag.setDisplayBids();
  }
}

/**
 * Initialize apstag library and set config to handle setting bid targeting.
 */
function setUpAmazon(app) {
  const { amazonPubId, amazonNCAEnabled, amazonNCAPubUUID } = app.settings.prebid;

  Logger.log(`Setting up Amazon with pubId: ${amazonPubId}.`);

  if (amazonPubId) {
    setUpNamespace(amazonNCAEnabled);
    initializeAmazon(amazonPubId, amazonNCAEnabled, amazonNCAPubUUID);
    enableNativeContent(amazonNCAEnabled);
  }
}

/**
 * Setting up Amazon Namespace and Methods
 * @param {Boolean} amazonNCAEnabled
 */
function setUpNamespace(amazonNCAEnabled) {
  const queueNamespace = amazonNCAEnabled ? 'queue' : '_Q';

  window.apstag = window.apstag || {
    [queueNamespace]: [],
    init: function() {
      apstag[queueNamespace].push(['i', arguments, new Date().getTime()]);
    },
    fetchBids: function() {
      apstag[queueNamespace].push(['f', arguments, new Date().getTime()]);
    },
    setDisplayBids: function() {},
    targetingKeys: function() {
      return [];
    },
  };
}

/**
 * Initializing Amazon
 * @param {Number} amazonPubId
 * @param {Boolean} amazonNCAEnabled
 * @param {String} amazonNCAPubUUID
 */
function initializeAmazon(amazonPubId, amazonNCAEnabled, amazonNCAPubUUID) {
  const apstagInitObj = {
    pubID: amazonPubId,
    adServer: 'googletag',
    videoAdServer: 'connatix',
    deals: true,
  };

  const canonicalUrl = getCanonicalUrl();

  if (amazonNCAEnabled) {
    apstagInitObj.signals = {
      ortb2: {
        site: {
          page: canonicalUrl,
          publisher: {
            id: amazonNCAPubUUID,
          },
        },
      },
    };
  }

  apstag.init(apstagInitObj);
}

/**
 * Enabling Native Content for APS NCA Integration
 * @param {Boolean} amazonNCAEnabled
 */
function enableNativeContent(amazonNCAEnabled) {
  if (amazonNCAEnabled) {
    apstag.queue.push(function() {
      window.apstag.nativeContent().enable();
    });
  }
}
